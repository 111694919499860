import login from "../../assets/images/logo/merchant.paypanda-logo.png";
import loginimg from "../../assets/images/logo/login.jpg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { LoginSubmit } from "../../api/login/Login";
import CustomInputField from "../../common/CustomInputField";
import { SaveUserDeatilsLocalStorage } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import { setIsLogin } from "../../slice/auth";
function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [otpVal, setOtpval] = useState({
    otp: "",
  })
  const dispatch = useDispatch();

  const handleChangeLogin = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const cloneData = { ...loginForm };
    cloneData[name] = value;
    setLoginForm(cloneData);
  };
  const handleOTPChange = (e) => {
    const otpValue = e.target.value;
    const otpName = e.target.name;
    const cloneOtpD = { ...otpVal };
    cloneOtpD[otpName] = otpValue;
    setOtpval(cloneOtpD)
  }

  // const submitSignIn = async () => {
  //   setLoading(true);
  //   try {
  //     let result = await LoginSubmit(loginForm);
  //     if (result.statusCode == "200") {
  //       setLoading(false);
  //       SaveUserDeatilsLocalStorage(result.data.token);
  //       dispatch(setIsLogin({ isLogin: !!result.data.token }));
  //       navigate("/admin");
  //     } else {
  //       throw new Error(result.data.message);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     alert(error.message);
  //   }
  // };

  // const submitSignIn = () => {
  //   navigate('/admin')
  // };

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [passInp, setPassInp] = useState(false);
  const handlePasswordInp = () => {
    setPassInp(!passInp)
  };


  const submitLoginData = () => {
    setShowOtpInput(true)
    console.log('loginForm--', loginForm)
  };
  const submitOTPData = () => {
    // setShowOtpInput(false)
    console.log('otpVal--', otpVal)
    navigate('/admin')
  };


  return (
    <>
      <div className="authincation h-100 h-100-2">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-lg-4 col-md-12 col-sm-12 mx-auto align-self-center">
              <div className="login-form">
                {showOtpInput ? <div className="text-center">
                  <h3 className="title">Enter OTP</h3>
                  <p>Enter OTP Sent To Your Mobile Number</p>
                </div> : <div className="text-center">
                  <h3 className="title">Sign In</h3>
                  <p>Sign in to your account to start using W3CRM</p>
                </div>}
                {/* <div className="text-center">
                  <h3 className="title">Sign In</h3>
                  <p>Sign in to your account to start using W3CRM</p>
                </div> */}
                <form action="#">
                  {!showOtpInput && <div>

                    <div className="mb-4">
                      <label className="mb-1 text-dark">Number</label>
                      <input type="number" placeholder="Enter Your Number" className="form-control form-control loginCustom-inp" name="email" value={loginForm?.email} defaultValue="hello@example.com" fdprocessedid="k2hurl" onChange={handleChangeLogin} />
                    </div>

                    <div className="mb-4 position-relative">
                      <label className="mb-1 text-dark">Password</label>
                      <input type={passInp ? "text" : "password"} id="dz-password" name="password" value={loginForm?.password} className="form-control loginCustom-inp" placeholder="Enter Password" fdprocessedid="c5s8m" onChange={handleChangeLogin} />
                      <span className="show-pass eye" onClick={handlePasswordInp}>
                        <i className="fa fa-eye-slash" />
                        <i className="fa fa-eye" />
                      </span>
                    </div>

                    <div className="text-center mb-4">
                      <button type="button" onClick={submitLoginData} className="btn btn-primary btn-block customSignInBtn" fdprocessedid="xptkvb">Sign In</button>
                    </div>
                  </div>}


                  {showOtpInput && <div> <div className="mb-4 position-relative">
                    <label className="mb-1 text-dark">Enter OTP</label>
                    <input type="text" id="dz-password" placeholder='Enter OTP' name="otp" value={otpVal?.otp} className="form-control loginCustom-inp" fdprocessedid="c5s8m" onChange={handleOTPChange} />
                    <span className="show-pass eye" onClick={handlePasswordInp}>
                      <i className="fa fa-eye-slash" />
                      <i className="fa fa-eye" />
                    </span>
                  </div>

                    <div className="text-center mb-4">
                      <button type="button" onClick={submitOTPData} className="btn btn-primary btn-block customSignInBtn" fdprocessedid="xptkvb">Submit OTP</button>
                    </div>

                  </div>
                  }

                  {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                        <div className="mb-4">
                                            <div className="form-check custom-checkbox mb-3">
                                                <input type="checkbox" className="form-check-input" id="customCheckBox1" required />
                                                <label className="form-check-label" htmlFor="customCheckBox1">Remember my preference</label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <a href="page-forgot-password.html" className="btn-link text-primary">Forgot Password?</a>
                                        </div>
                                    </div> */}

                  {/* <h6 className="login-title"><span>Or continue with</span></h6>
                                    <div className="mb-3">
                                        <ul className="d-flex align-self-center justify-content-center">
                                            <li><a target="_blank" href="https://www.facebook.com/" className="fab fa-facebook-f btn-facebook" /></li>
                                            <li><a target="_blank" href="https://www.google.com/" className="fab fa-google-plus-g btn-google-plus mx-2" /></li>
                                            <li><a target="_blank" href="https://www.linkedin.com/" className="fab fa-linkedin-in btn-linkedin me-2" /></li>
                                            <li><a target="_blank" href="https://twitter.com/" className="fab fa-twitter btn-twitter" /></li>
                                        </ul>
                                    </div>
                                    <p className="text-center">Not registered?
                                        <a className="btn-link text-primary" href="page-register.html">Register</a>
                                    </p> */}
                </form>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="pages-left h-100">
                <div className="login-content">
                  <a href="index.html"><img style={{ width: '100px' }} src="https://www.abarissoftech.com/assets/img/logo-2.png" className="mb-3 logo-dark" alt /></a>
                  {/* <a href="index.html"><img src="images/logi-white.png" className="mb-3 logo-light" alt /></a> */}
                  {/* <a href="index.html"><img style={{ width: '98px', height: '24px' }} src="https://www.abarissoftech.com/assets/img/logo-2.png" className="mb-3 logo-light" alt /></a> */}
                  <p>CRM dashboard uses line charts to visualize customer-related metrics and trends over time.</p>
                </div>
                <div className="login-media text-center">
                  <img src="https://w3crm.dexignzone.com/xhtml/images/login.png" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
