
function Loadar() {
    return (
        <>
            <div className="loader-overlay">
                <div className="loader"></div>
            </div>
        </>
    )
}
export default Loadar