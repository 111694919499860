import { Navigate, Route, Routes } from "react-router-dom";
import "./assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./common/CustomInputField/index.module.scss";
import DasBoardPage from "./pages/dasBoardPage";
import DasBoardRight from "./pages/dasBoardRight/DasBoardRight";
import LoginPage from "./pages/login";
import PrivateRoute from "./privateRote/PrivateRoute";
import { useSelector } from "react-redux";
import { getToken } from "./utils/localStorage";
import { useDispatch } from "react-redux";
import { setIsLogin } from "./slice/auth";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Transactions from "./pages/transactions";
import CountryPage from "./pages/country";
import { getBodyThemeD } from "./api/login/Login";


function App() {
  const [bodyAttributesD, setBodyAttributesD] = useState();
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const hostname = window.location.hostname;
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };

    getLocation();
  }, []);


  const getBodyThemeData = async () => {
    try {
      const result = await axios.get(`https://api.abarispay.com/api/chkDomain`);
      setBodyAttributesD(result?.data?.data)
      console.log('result---', result?.data?.data)
    } catch (error) {
      alert('error')
    }
  }
  useEffect(() => {
    getBodyThemeData()
  }, []);

  window.localStorage.setItem('adminName', bodyAttributesD?.admin_name);
  window.localStorage.setItem('dataHeaderBg', bodyAttributesD?.data_headerbg);
  window.localStorage.setItem('dataNavHeaderBg', bodyAttributesD?.data_nav_headerbg);
  window.localStorage.setItem('dataPrimary', bodyAttributesD?.data_primary);
  window.localStorage.setItem('dataSecondary', bodyAttributesD?.data_secondary);
  window.localStorage.setItem('dataSidebarStyle', bodyAttributesD?.data_sidebar_style);
  window.localStorage.setItem('dataSIdebarBg', bodyAttributesD?.data_sidebarbg);
  window.localStorage.setItem('dataThemeVersion', bodyAttributesD?.data_theme_version);
  window.localStorage.setItem('dataTypography', bodyAttributesD?.data_typography);
  window.localStorage.setItem('loginImage', bodyAttributesD?.login_img);
  window.localStorage.setItem('logoUrl', bodyAttributesD?.logo_url);

  const adminName = window.localStorage.getItem('adminName');
  const dataHeaderBg = window.localStorage.getItem('dataHeaderBg');
  const dataNavHeaderBg = window.localStorage.getItem('dataNavHeaderBg');
  const dataPrimary = window.localStorage.getItem('dataPrimary');
  const dataSecondary = window.localStorage.getItem('dataSecondary');
  const dataSidebarStyle = window.localStorage.getItem('dataSidebarStyle');
  const dataSIdebarBg = window.localStorage.getItem('dataSIdebarBg');
  const dataThemeVersion = window.localStorage.getItem('dataThemeVersion');
  const dataTypography = window.localStorage.getItem('dataTypography');
  const loginImage = window.localStorage.getItem('loginImage');
  const logoUrl = window.localStorage.getItem('logoUrl');


  const bodyElem = document.getElementById("abc")
  bodyElem.getAttribute("data-typography");
  bodyElem.getAttribute("data-theme-version");
  bodyElem.getAttribute("data-layout");
  bodyElem.getAttribute("data-nav-headerbg");
  bodyElem.getAttribute("data-headerbg");
  bodyElem.getAttribute("data-sidebar-style");
  // bodyElem.getAttribute("data-sidebarbg");
  bodyElem.getAttribute("data-sidebar-position");
  bodyElem.getAttribute("data-header-position");
  bodyElem.getAttribute("data-container");
  bodyElem.getAttribute("data-primary");
  bodyElem.getAttribute("data-secondary");



  bodyElem.setAttribute('data-typography', dataTypography)
  bodyElem.setAttribute('data-theme-version', dataThemeVersion)
  bodyElem.setAttribute('data-nav-headerbg', dataNavHeaderBg)
  bodyElem.setAttribute('data-headerbg', dataHeaderBg)
  bodyElem.setAttribute('data-sidebar-style', dataSidebarStyle)
  bodyElem.setAttribute('data-primary', dataPrimary)
  bodyElem.setAttribute('data-secondary', dataSecondary)

  // bodyElem.setAttribute('data-theme-version', )




  //   function setAttributes(el, attrs) {
  //   for(var key in attrs) {
  //     el.setAttribute(key, attrs[key]);
  //   }
  // }


  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const { isLogin } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const location = useLocation();
  // console.log('locationD----',location)

  // useEffect(() => {
  //   console.log(getToken());
  //   if (getToken()) {
  //     dispatch(setIsLogin({ isLogin: true }));
  //     navigate(location?.pathname);
  //   }
  // }, []);

  // useEffect(() => {
  //   setIsAuthenticated(isLogin);
  // }, [isLogin]);




  return (
    <>
      <Routes>
        <Route path="/loginPage" element={<LoginPage />} />
        <Route path="/" element={<Navigate to="/loginPage" />} />

        {/* <Route path="admin" element={<PrivateRoute isAuthenticated={isAuthenticated} />} > */}
        <Route path="admin" element={<DasBoardPage />}>
          <Route path="" element={<DasBoardRight />} />
          <Route path="all-transactions-report" element={<CountryPage />} />
        </Route>



        {/* <Route path="*" element={<LoginPage />} /> */}

        {/* <Route path="*" element={<Navigate to="/loginPage" />}/> */}
        {/* </Route> */}
      </Routes>
    </>
  );
}
export default App;
